import React  from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import BloqueContacto from "../components/bloque-contacto"

import { FiMapPin, FiLock, FiTag, FiAward } from "react-icons/fi";
import { tiempoGarantizado, centroSoporte, ciberseguridad, inventario, monitor, copiaSeguridad } from '../images/necesitas';
import { default as outsourcing } from '../images/outsourcing/outsourcing.svg';
import { default as bolsa_horas } from '../images/bolsa-horas/bolsa-horas.svg';
import { manuel_cantueso, jordi_serra, anna_prieto, quote } from '../images/testimoniales';


const SoporteInformaticoPage = () => {


    return (
        <Layout className="page-soporte">
            <SEO 
                title="Soporte y Mantenimiento Informático en Barcelona" 
                description="Mantenimiento informático para empresas con soporte en Barcelona, Outsourcing, Helpdesk, CAU y servicio técnico."
                />

            <section className="bloque bloque-featured">
                <div className="container featured">
                    <div className="bloque-header">
                        <h1>El Soporte Informático que te dejará tranquilo</h1>
                        <h2>Soporte informático rápido y fiable para pymes de Barcelona ¿Hablamos?</h2>
                    </div>
                    <Link className="btn btn-accent" to="/contacto" title="Contacta con dms">Contactar</Link>
                </div>


                <div className="super-powers">
                    <div className="container">
                        <div className="row">
                            <article className="col-md-6 col-lg-3">
                                <span className="icono-bola icono-power">
                                    <FiMapPin />
                                </span>
                                <h3>Estamos en barcelona</h3>
                                <p>Nunca más tendrás que esperar</p>
                            </article>
                            <article className="col-md-6 col-lg-3">
                                <span className="icono-bola icono-power">
                                    <FiLock />
                                </span>
                                <h3>Ciberseguridad</h3>
                                <p>Nos aseguramos de que tus datos estén seguros</p>
                            </article>
                            <article className="col-md-6 col-lg-3">
                                <span className="icono-bola icono-power">
                                    <FiTag />
                                </span>
                                <h3>Desde 190€/mes</h3>
                                <p>Precios flexibles adaptados a tu tamaño</p>
                            </article>
                            <article className="col-md-6 col-lg-3">
                                <span className="icono-bola icono-power">
                                    <FiAward />
                                </span>
                                <h3>Fiabilidad</h3>
                                <p>Sólo nos gusta el trabajo bien hecho</p>
                            </article>																		

                        </div>
                    </div>
                </div>      
            </section>


            <section className="bloque-necesitas">
                <div className="container">

                    <div className="bloque-header">
                        <h2 className="title">Una cuota con todo lo que necesitas para estar tranquilo</h2>
                    </div>

                    <div className="row align-items-center justify-content-center ventajas">
                        <div className="col-lg-4 col-md-6 ventaja">
                            <img src={tiempoGarantizado} alt="tiempo-garantizado" />
                            <h3 className="info">Tiempo de respuesta garantizado</h3>
                        </div>

                        <div className="col-lg-4 col-md-6 ventaja">
                            <img src={centroSoporte} alt="centro-soporte" />
                            <h3 className="info">Centro de Soporte 24x7</h3>
                        </div>

                        <div className="col-lg-4 col-md-6 ventaja">
                            <img src={ciberseguridad} alt="ciberseguridad" />
                            <h3 className="info">Soluciones de Ciberseguridad</h3>
                        </div>

                        <div className="col-lg-4 col-md-6 ventaja">
                            <img src={copiaSeguridad} alt="copia-seguridad" />
                            <h3 className="info">Copia de Seguridad en la nube</h3>
                        </div>

                        <div className="col-lg-4 col-md-6 ventaja">
                            <img src={monitor} alt="monitorizacion-remota" />
                            <h3 className="info">Monitorización remota</h3>
                        </div>

                        <div className="col-lg-4 col-md-6 ventaja">
                            <img src={inventario} alt="inventario" />
                            <h3 className="info">Inventario de Hardware y software</h3>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bloque-outsourcing">
                <div className="container">
                    <div className="outsourcing row align-items-center">
                        <span className="outsourcing-img col-lg-5">
                            <img src={outsourcing} alt="outsourcing" />
                        </span>
                        <div className="outsourcing-info col-lg-7">
                            <h2 className="title">Outsourcing </h2>
                            <p>Con el Outsourcing de DMS incorporamos a tu equipo los perfiles técnicos que necesites. Además, podemos proveer distintos niveles de servicio para atender todo tipo de inciencias técnicas y para dar cobertura 24x7.</p>
                       </div>
                    </div>
                </div>
            </section>


            <section className="bloque-bolsa-horas">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">Bolsa de horas</h2>
                    </div>
                    <div className="bolsa-horas">
                        <img src={bolsa_horas} className="bolsa-horas-img" alt="bolsa-horas" />
                        <p className="bolsa-horas-info">Si necesitas que demos soporte a tu Infraestructura y a tus usuarios pero solo para atender incidencias o consultas podemos ofrecerte bolsas de horas adaptadas a tus necesidades</p>
                    </div>
                </div>
            </section>

            <section className="bloque-testimoniales">
                <div className="container">
                    <div className="bloque-header">
                        <h2 className="title">232 pymes de Barcelona felices</h2>
                    </div>

                    <div className="testimoniales-perfiles">
                        <article className="perfil">
                            <div className="perfil-header">
                                <div className="perfil-header-info">
                                    <h3 className="name">Manuel Cantueso</h3>
                                    <p className="role">Socio de Bellavista</p>
                                </div>
                                <img src={manuel_cantueso} className="avatar" alt="Foto Manuel Cantueso" />
                            </div>
                            <div className="perfil-info">
                                <div className="box">
                                    <img src={quote}  className="quotes" alt="quote" />
                                    <p className="box-info">
                                        “Antoni y su equipo ofrecen un servicio de alta calidad, ofreciendo soluciones de bajo coste, y siempre con una respuesta muy rápida, lo que es especialmente importante en el tema informático.”
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className="perfil">
                            <div className="perfil-header">
                                <div className="perfil-header-info">
                                    <h3 className="name">Jordi Serra</h3>
                                    <p className="role">Plant Manager en Isofel</p>
                                </div>
                                <img src={jordi_serra} className="avatar" alt="Foto Jordi Serra" />
                            </div>
                            <div className="perfil-info">
                                <div className="box">
                                    <img src={quote} className="quotes" alt="quote" />
                                    <p className="box-info">
                                        “DMS lleva más de 6 años administrando nuestra infraestructura informática y puedo decir que son proactivos e insistentes y siempre consiguen ponerlo todo en orden.”
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className="perfil">
                            <div className="perfil-header">
                                <div className="perfil-header-info">
                                    <h3 className="name">Anna Prieto</h3>
                                    <p className="role">Administración en Terraqui</p>
                                </div>
                                <img src={anna_prieto} className="avatar" alt="Foto Anna Prieto" />
                            </div>
                            <div className="perfil-info">
                                <div className="box">
                                    <img src={quote} className="quotes" alt="quote" />
                                    <p className="box-info">
                                        “Estamos muy satisfechos con la calidad y profesionalidad del personal de DMS. Agradecemos especialmente su grado de reacción ante cualquier incidencia que tengamos. También destacamos su amabilidad y educación de todos y cada uno de ellos.”
                                    </p>
                                </div>
                            </div>
                        </article>

                    </div>
                </div>
            </section>

            <BloqueContacto />
        </Layout>
    );
};

export default SoporteInformaticoPage